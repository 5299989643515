import type { RouteComponentProps } from "@reach/router";

import {
  type CurriculumLessonId,
  CurriculumClassPageController,
  CurriculumSessionLoader,
} from "../../../curriculum";
import { assertDefined } from "../../../utils";

export type CurriculumDppClass5PageProps = Readonly<RouteComponentProps>;

export default function CurriculumDppClass5Page({
  location,
}: CurriculumDppClass5PageProps) {
  assertDefined(location);

  return (
    <CurriculumSessionLoader location={location}>
      <CurriculumClassPageController
        lessonId={"class5" as CurriculumLessonId}
        locationPathname={location.pathname}
      />
    </CurriculumSessionLoader>
  );
}
